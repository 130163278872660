<template>
  <base-detail-view
    :loading="loading"
    :title="'Учебный материал, ' + typeMaterials[dataSource.Type || 0] + ''"
    v-bind="!readonly ? { isUnsaved: isUnsaved && valid } : {}"
    @onSave="save"
    @onRefresh="init"
    :show-save="!isEmployee"
  >
    <v-btn
      slot="action"
      @click="close"
      dark
      color="primary"
      class="ml-4"
      title="Закрыть"
    >
      <m-icon icon="mdi-close" left></m-icon>
      Закрыть
    </v-btn>
    <v-form
      v-if="!isEmployee"
      class="block__content"
      :readonly="readonly"
      v-model="valid"
    >
      <v-container>
        <v-row>
          <v-col>
            <v-text-field
              v-model="dataSource.Name"
              label="Название"
              maxlength="200"
              autofocus
              v-up-first-symbol
              required
              :rules="[rules.required]"
            ></v-text-field>
            <v-text-field
              :value="dataSource.Time / 60"
              @input="
                (value) => {
                  dataSource.Time = value * 60;
                }
              "
              label="Время на выполнение задания"
              type="number"
              suffix="Мин"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="dataSource.Description"
              label="Комментарий"
              maxlength="200"
            ></v-text-field>
            <v-text-field
              v-model="dataSource.AllowedErrorsCount"
              label="Допустимое количество ошибочных ответов"
              type="number"
              required
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <testing-questions
      v-if="!isEmployee"
      class="mt-2"
      :value="dataSource.Questions"
    />
    <testing-run
      v-else
      class="mt-2"
      :is-started="isStarted"
      :is-done="isDone"
      :comment="dataSource.Comment"
      @start="testStart"
      @execute="execute"
      :test="dataSource"
    />
  </base-detail-view>
</template>
<script>
import UnsavedMixin from "@/mixins/UnsavedMixin.js";
import CreateAndEditMixin from "@/mixins/CreateAndEditMixin.js";
import BaseDetailView from "@/layouts/BaseDetailView.vue";
import KnowledgeBaseService from "@/services/KnowledgeBaseService";

import { KnowledgeBase } from "@/data/defaultObjects";

export default {
  name: "view-TestEdit",
  components: {
    BaseDetailView,
    testingQuestions: () => import("./components/TestingQuestions.vue"),
    TestingRun: () => import("./components/run"),
  },
  mixins: [CreateAndEditMixin, UnsavedMixin],
  provide() {
    return {
      test: this.dataSource,
    };
  },
  props: {
    isEmployee: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      apiService: !this.isEmployee
        ? KnowledgeBaseService.test
        : KnowledgeBaseService.testEmployee,
      loading: true,
      dataSource: KnowledgeBase.material(),
      getDefaultObject: KnowledgeBase.material(),
      valid: false,
      readOnly: false,
      rules: {
        required: (value) => !!value || value === 0 || "Укажите значение.",
      },
      typeMaterials: ["Тест", "word", "Документ", "видео Youtube"],
      checkBox: false,
      getObjectText: () => this.dataSource.Name,
      funcUpdateItem: null,
    };
  },
  computed: {
    isStarted() {
      return !(
        this.dataSource.Start === null && this.dataSource.Result === null
      );
    },
    isDone() {
      return !!this.dataSource.Date;
    },
    readonly() {
      return false;
    },
  },
  watch: {
    "dataSource.Result": {
      deep: true,
      handler: function () {
        if (this.funcUpdateItem) {
          this.funcUpdateItem(this.dataSource);
        }
      },
    },
  },
  async created() {
    this.funcUpdateItem =
      this.$store.state.pageTabs.transaction.listViewDetail.listViewUpdateItems;
    this.$store.dispatch("pageTabs/TAKE_TRANSACTION_VIEW_DETAIL");
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async save() {
      if (this.isEmployee) {
        this.execute();
        return true;
      }
      return this.baseSave();
    },
    async execute() {
      this.dataSource = (
        await KnowledgeBaseService.testEmployee.execute(
          this.dataSource.TestId,
          this.dataSource.QuestionResults
        )
      ).data;
      this.originObject = this.getActualDataSources(this.dataSource);
    },
    async testStart(restart = false) {
      this.dataSource = (
        await KnowledgeBaseService.testEmployee.start(
          this.dataSource.TestId,
          restart
        )
      ).data;
      this.originObject = this.getActualDataSources(this.dataSource);
    },
    close() {
      const activeTab = this.$store.state.pageTabs.tabs.find(
        (t) => t.route === this.$store.state.pageTabs.activeTab
      );

      if (!activeTab || Object.keys(activeTab).length === 0) return;

      this.$store.dispatch("pageTabs/CLOSE_TAB", {
        tab: activeTab,
        _router: this.$router,
      });
    },
  },
};
</script>
<style lang="scss">
.matarial-edit {
  &__confirm-inner-btn {
    align-self: center;
  }
  &__confirm-checkbox .v-label {
    font-weight: bold;
  }
  &__confirm-inner-checkbox {
    flex-grow: 0;
    white-space: nowrap;
  }
  &__material-empty {
    text-align: center;
    margin: 40px 0;
  }
}
</style>
